window.hamburger = {
  in_megamenu_class  : 'is-in-megamenu',
  is_open_class  : 'main-menu-is-open',
  is_active_class: 'is-active',

  init: function(breakpoint) {
    this.current_bp = breakpoint

    const obj = this
    const hamburger = document.querySelector('.l-header__hamburger')

    hamburger.addEventListener('click', function() {
      if (hamburger.classList.contains(window.hamburger.is_active_class)) {
        hamburger.classList.remove(window.hamburger.is_active_class)
        document.body.classList.remove(window.hamburger.is_open_class)
      } else {
        hamburger.classList.add(window.hamburger.is_active_class)
        document.body.classList.add(window.hamburger.is_open_class)
      }

      return false;
    })
  },
}

// window.addEventListener('breakpointchanged', function(e) {
// 	window.hamburger.resize(e.breakpoint)
// })

window.hamburger.init(window.breakpoints.current)


document.onkeydown = function(evt) {
  evt = evt || window.event;
  if (evt.keyCode == 27) {
    //escape
    if (document.body.classList.contains('main-menu-is-open')) {
      hamburger.classList.remove('is-active')
      document.body.classList.remove('main-menu-is-open')
    }
  }
};
