window.faq = {
  title_selector: '.m-faq__itemtitle',
  item_selector: '.m-faq__item',
  itemcontent_selector: '.m-faq__itemcontent',
  is_open_class: 'is-opened',

  init: function(base, list_item) {
    const faqtitle = document.querySelectorAll(window.faq.title_selector)
    const faqlist = document.querySelectorAll(window.faq.item_selector)

    for (const faqtrigger of faqtitle) {
      // click
      faqtrigger.addEventListener('click', function(e) {
        const faqitem = faqtrigger.parentNode
        const itemcontent = faqitem.querySelector(window.faq.itemcontent_selector)

        if (faqitem.classList.contains(window.faq.is_open_class)) {
          itemcontent.removeAttribute('style')
          faqitem.classList.remove(window.faq.is_open_class)
        } else {
          // close other
          for (const faqlistitem of faqlist) {
            const listitemcontent = faqlistitem.querySelector(window.faq.itemcontent_selector)

            listitemcontent.removeAttribute('style')
            faqlistitem.classList.remove(window.faq.is_open_class)
          }

          // open selected
          itemcontent.style.maxHeight = itemcontent.scrollHeight + 'px'

          faqitem.classList.add(window.faq.is_open_class)
        }

        return false;
      }, false);
    }
  },

  resize: function() {
    const faqresizeitems = document.querySelectorAll(window.faq.item_selector)

    for (const faqresizeitem of faqresizeitems) {
      if (faqresizeitem.classList.contains(window.faq.is_open_class)) {
        const resizeitemcontent = faqresizeitem.querySelector(window.faq.itemcontent_selector)

        resizeitemcontent.style.maxHeight = resizeitemcontent.scrollHeight + 'px'
      }
    }
  }
}

window.addEventListener('load', function () {
  window.faq.init();
});

const windowWidth = window.innerWidth;

window.addEventListener('resize', function () {
  if (window.innerWidth !== windowWidth) {
    window.faq.resize();
  }
});
