window.navigation = {
  dropdowns: [],
  is_hidden_class: 'is-hidden',
  is_open_class: 'is-opened',
  is_open_submenu_class: 'sub-menu-is-open',
  // overviewitem_class: 'menu-overview',
  titleitem_class: 'menu-subtitle',
  backitem_class: 'menu-back',

  init: function () {
    const mainMenu = document.querySelector('.m-mainnav');

    if (!mainMenu) return;

    window.navigation.intrail();

    window.navigation.submenus();

    const dropdowns = mainMenu.querySelectorAll(
      '.menu-item.menu-item-has-children > a',
    );

    if (dropdowns) {
      navigation.dropdowns = [...dropdowns];

      navigation.dropdowns.map((item) => {
        item.addEventListener('click', window.navigation.toggle);
      });
    }
  },

  resize: function() {
    window.navigation.intrail()
  },

  intrail: function() {
    // console.log(window.breakpoints.current)

    if (window.breakpoints.current !== 'xl') { 
      // mobile
      const currentAncestors = document.querySelectorAll('.m-mainnav .current-menu-ancestor')

      if (currentAncestors) {
        for (const currentAncestor of currentAncestors) {
          currentAncestor.classList.add(window.navigation.is_open_class)

          if (currentAncestor.classList.contains('level-1') && (currentAncestor.querySelectorAll('.sub-menu').length > 0)) {
            currentAncestor.closest('.sub-menu').classList.add(window.navigation.is_hidden_class);
          }
        }
      }
    } else {
      // desktop
      if (!document.body.classList.contains(window.navigation.is_open_class)) {
        const currentAncestors = document.querySelectorAll('.m-mainnav .current-menu-ancestor')

        if (currentAncestors) {
          for (const currentAncestor of currentAncestors) {
            currentAncestor.classList.remove(window.navigation.is_open_class)

            if (currentAncestor.classList.contains('level-1') && (currentAncestor.querySelectorAll('.sub-menu').length > 0)) {
              currentAncestor.closest('.sub-menu').classList.remove(window.navigation.is_hidden_class);
            }
          }
        }
      }
    }
  },

  submenus: function () {
    let backtext = 'back';
    // let overviewtext = 'to overview';
    let html = document.querySelector('html');

    if (html) {
        let lang = html.getAttribute('lang');
        if (lang == 'de-DE') {
          backtext = 'zurück';
          // overviewtext = 'zur Übersicht';
        }
    }

    // 1st levels
    // const firstSubMenus = document.querySelectorAll(
    //   '.m-mainnav > .level-0 > .sub-menu',
    // );

    // for (const firstSubMenu of firstSubMenus) {
    //   // add overview link
    //   const parentLink = firstSubMenu.parentElement.firstChild.getAttribute('href');

    //   const overviewitem = document.createElement('li');
    //   overviewitem.classList.add(window.navigation.overviewitem_class);
    //   overviewitem.innerHTML = '<a href="'+parentLink+'" class="'+window.navigation.overviewitem_class+'-link">'+overviewtext+'</a>';

    //   firstSubMenu.insertBefore(overviewitem, firstSubMenu.firstChild);
    // }
    
    // 3rd levels / submenus
    const secondSubMenus = document.querySelectorAll(
      '.m-mainnav > .level-0 > .sub-menu > .level-1 > .sub-menu',
    );

    for (const secondSubMenu of secondSubMenus) {
      // add title
      const parentTitle = secondSubMenu.parentElement.firstChild.innerText;
      const parentLink = secondSubMenu.parentElement.firstChild.getAttribute('href');

      if (parentTitle) {
        const titleitem = document.createElement('li');
        titleitem.classList.add(window.navigation.titleitem_class);
        titleitem.innerHTML = '<a href="'+parentLink+'" class="'+window.navigation.titleitem_class+'-link">'+parentTitle+'</a>';

        secondSubMenu.insertBefore(titleitem, secondSubMenu.firstChild);
      }

      // add back link
      const backitem = document.createElement('li');
      backitem.classList.add(window.navigation.backitem_class);
      backitem.innerHTML = '<a href="#" class="'+window.navigation.backitem_class+'-link">'+backtext+'</a>';

      secondSubMenu.appendChild(backitem);
    }

    // backlink click
    const backlinks = document.querySelectorAll(
      '.m-mainnav .menu-back-link',
    );

    for (const backlink of backlinks) {
      backlink.addEventListener('click', function(e) {
        e.preventDefault()

        e.target.closest('.menu-item.level-1').classList.remove(window.navigation.is_open_class);
        e.target.closest('.menu-item.level-1').closest('.sub-menu').classList.remove(window.navigation.is_hidden_class);
      })
    }
  },

  toggle: (e) => {
    e.preventDefault();

    navigation.dropdowns.map((item) => {
      if ((item !== e.target) && (!e.target.closest('.sub-menu'))) {
        item.parentElement.classList.remove(window.navigation.is_open_class);
        item.parentElement.querySelector('.sub-menu').classList.remove(window.navigation.is_hidden_class);
      }
    });

    const parentWrapper = e.target.parentElement;

    // classes
    parentWrapper.classList.toggle(window.navigation.is_open_class);

    if (parentWrapper.classList.contains(window.navigation.is_open_class)) {
      document.body.classList.add(window.navigation.is_open_submenu_class);
    } else {
      document.body.classList.remove(window.navigation.is_open_submenu_class);

      // submenu
      if (e.target.closest('.menu-item').classList.contains('level-0') && (e.target.closest('.menu-item').querySelector('.sub-menu').classList.contains(window.navigation.is_hidden_class))) {
        e.target.closest('.menu-item.level-0').querySelector('.sub-menu').classList.remove(window.navigation.is_hidden_class);
      }
    }

    // submenues
    if (e.target.closest('.menu-item').classList.contains('level-1') && (e.target.closest('.menu-item').querySelectorAll('.sub-menu').length > 0)) {
      e.target.closest('.sub-menu').classList.add(window.navigation.is_hidden_class);
    }

    // close
    window.navigation.close();
  },

  close: function () {
    // close by click outside
    if (document.body.classList.contains(window.navigation.is_open_submenu_class)) {
      document.body.addEventListener('click', function (e) {
        if (!e.target.closest('.m-mainnav')) {
          document.body.classList.remove(window.navigation.is_open_submenu_class);

          const toggleItem = document.querySelector('.m-mainnav .menu-item.' + window.navigation.is_open_class);
          if (toggleItem) {
            toggleItem.classList.remove(window.navigation.is_open_class);
          }

          // submenues
          if (document.querySelectorAll('.m-mainnav .menu-item .sub-menu').length > 0) {
            document.querySelector('.m-mainnav .menu-item .sub-menu').classList.remove(window.navigation.is_hidden_class);
          }
        }
      });
    }
  },
};

window.addEventListener('breakpointchanged', function(e) {
	window.navigation.resize()
})

window.navigation.init(window.breakpoints.current);
