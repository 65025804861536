window.searchform = {
  init: function (searchform) {
    const closer = document.querySelector('.m-search__close');

    searchform.addEventListener('click', window.searchform.overlayevent);
    closer.addEventListener('click', window.searchform.overlayevent);
  },

  overlayevent: function () {
    const searchoverlay = document.querySelector('.l-search');
    searchoverlay.classList.toggle('open');

    document.body.classList.toggle('search-overlay-is-open');

    if (searchoverlay.classList.contains('open')) {
      const input = searchoverlay.querySelector('.m-search .m-search__input');
      if (input) {
        input.focus();
      }

      document.body.classList.add('search-overlay-is-open');

      // click outside
      document.body.addEventListener('click', function (e) {
        if (!e.target.closest('.l-search__overlay') && !e.target.closest('.l-header__searchbutton')) {
          searchoverlay.classList.remove('open');
          document.body.classList.remove('search-overlay-is-open');
        }
      });
    } else {
      document.body.classList.remove('search-overlay-is-open');
    }
  },
};

const searchform = document.querySelector('.l-header__searchbutton');
if (searchform) {
  window.searchform.init(searchform);
}

const searchbox = document.querySelector('.l-search');
if (searchbox) {
  searchbox.classList.remove('hidden');
}
