window.sharer = {
  is_active_class: 'is-active',

  init: function() {
    const sharer = document.querySelector('.m-share')
    const sharerLink = document.querySelector('.m-share__sharelink')

    if (sharerLink) {
      sharerLink.addEventListener('click', function(e) {
        e.stopPropagation();
        e.preventDefault();

        sharer.classList.toggle(window.sharer.is_active_class);
      });

      // close by click outside
      document.body.addEventListener('click', function (e) {
        if (sharer.classList.contains(window.sharer.is_active_class)) {
          if (!e.target.closest('.m-share')) {
            sharer.classList.remove(window.sharer.is_active_class);
          }
        }
      });
    }
  },
}

window.sharer.init()
