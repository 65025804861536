window.teaserbox = {
  init: function () {
    const teaserboxesCE = document.querySelectorAll('.m-teaserbox');

    for (const teaserboxCE of teaserboxesCE) {
      const boxtitles = teaserboxCE.querySelectorAll('.m-teaserbox__boxtitle');

      if (boxtitles.length > 1) {
        window.teaserbox.equalheights(boxtitles);
      }
    }
  },

  equalheights: function (boxtitles) {
    const heights = [];

    const teaserbox = document.querySelector('.m-teaserbox__boxes:first-child');

    if (teaserbox) {
      const teaserboxrect = teaserbox.getBoundingClientRect();
      const teaserboxwidth = teaserboxrect.width;

      for (const boxtitle of boxtitles) {
        if (boxtitle.getAttribute('style')) {
          boxtitle.removeAttribute('style');
        }

        const boxlink = boxtitle.closest('.m-teaserbox__boxlink');
        const boxlinkrect = boxlink.getBoundingClientRect();
        const boxlinkwidth = boxlinkrect.width;

        const rect = boxtitle.getBoundingClientRect();
        const height = rect.height;
        
        if (teaserboxwidth != boxlinkwidth) {
          heights.push(height);
        }
      }

      if (window.breakpoints.current != 'xs') {
        // remove duplicate values
        let heightsClean = [...new Set(heights)];

        if (heightsClean.length > 1) {
          // larges height
          let equalHeight = Math.max.apply(Math, heights);

          for (const boxtitle of boxtitles) {
            const boxlink = boxtitle.closest('.m-teaserbox__boxlink');
            const boxlinkrect = boxlink.getBoundingClientRect();
            const boxlinkwidth = boxlinkrect.width;

            if (teaserboxwidth != boxlinkwidth) {
              boxtitle.style.height = Math.ceil(equalHeight) + 'px';
            }
          }
        }
      }
    }
  },
};

window.addEventListener('load', function () {
  window.teaserbox.init();
});

window.addEventListener('resize', function () {
  window.teaserbox.init();
});
