window.languageswitcher = {
  language_selector: '.m-languageswitcher ul li.wpml-ls-current-language a',
  language_wrapper: '.m-languageswitcher ul',
  is_open_class: 'is-opened',

  init: function(base, list_item) {
    const languagetrigger = document.querySelector(window.languageswitcher.language_selector)

    if (languagetrigger) {
      const languagecontainer = languagetrigger.closest(window.languageswitcher.language_wrapper)

      const childcnt = languagecontainer.children.length

      languagecontainer.setAttribute('data-cnt', childcnt)

      if (childcnt > 1) {
        languagetrigger.addEventListener('click', function(e) {
          e.preventDefault()

          languagecontainer.classList.toggle(window.languageswitcher.is_open_class)

          return false;
        }, false);

        // close by click outside
        document.body.addEventListener('click', function (e) {
          if (languagecontainer.classList.contains(window.languageswitcher.is_open_class)) {
            if (!e.target.closest('.m-languageswitcher')) {
              languagecontainer.classList.remove(window.languageswitcher.is_open_class);
            }
          }
        });
      }
    }
  }
}

window.addEventListener('load', function() {
  window.languageswitcher.init()
});
