window.header = {
  init: function() {
		const header = document.querySelector('.l-header');
		const headerRect = header.getBoundingClientRect();
		const headerHeight = headerRect.height;

		if (!document.body.classList.contains('js-has-scrolled')) {
			if (window.scrollY > headerHeight) {
				document.body.classList.add('js-has-scrolled');
			}
		} else {
			if (window.scrollY <= headerHeight) {
				document.body.classList.remove('js-has-scrolled');
			}
		}
  }
}

window.addEventListener('load', function() {
	window.header.init();
});

window.addEventListener('scroll', function() {
	window.header.init();
}, false);
