/**
 * Load More
 * add button like
 * <div class="loadmore" data-type="association" data-maxpages="{!! (int) $posts->max_num_pages !!}" data-curpage="1" data-list=".class">{{ __('Mehr laden', 'dgpm')}}</div>
 */
document.addEventListener('DOMContentLoaded', function (event) {
  let el = document.querySelector('.loadmore');

  if (el) {
    el.addEventListener('click', function (e) {
      var xhttp = new XMLHttpRequest();
      xhttp.open('POST', '/wp/wp-admin/admin-ajax.php', true);
      xhttp.setRequestHeader(
        'Content-type',
        'application/x-www-form-urlencoded; charset=UTF-8',
      );
      xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          // Response
          let response = this.responseText;
          let curPage = parseInt(el.getAttribute('data-curpage'));
          let maxPages = parseInt(el.getAttribute('data-maxpages'));
          const listWrapper = el.getAttribute('data-list');

          el.setAttribute('data-curpage', ++curPage);

          if (curPage >= maxPages) {
            el.remove();
          }

          //add content
          let content = document.querySelector(listWrapper);
          content.insertAdjacentHTML('beforeend', response);
        }
      };

      let sendVar =
        'action=loadmore_posts&pageType=' +
        el.getAttribute('data-type') +
        '&curPage=' +
        parseInt(el.getAttribute('data-curpage'));

      if (el.getAttribute('data-association-id')) {
        sendVar += '&associationID=' + el.getAttribute('data-association-id');
      }

      xhttp.send(sendVar);
    });
  }
});
