import 'events-polyfill'

window.breakpoints = {
  current: '',
  breakpoints: [],

  match: function () {
    try {
      //reset
      window.breakpoints.breakpoints = []

      var breakpoints = window.dgpmconfig.breakpoints;

      for (var breakpoint in breakpoints) {
        var mql = window.matchMedia(breakpoints[breakpoint])
        if (mql.matches === true) {
          window.breakpoints.breakpoints.push(breakpoint)
        }
      }

      return window.breakpoints.breakpoints
    } catch (e) {
      // alert(e)
    }
  },
  get: function () {
    //console.log($.breakpoints.breakpoints);
  },

  change: function () {
    var breakpoints = window.breakpoints.match()

    if (breakpoints) {
      var breakpoint = breakpoints.slice(-1).pop()

      if (window.breakpoints.current !== breakpoint) {
        //$(document).trigger('breakpointchanged', {})
        window.breakpoints.current = breakpoint

        //fire event
        var event = new Event('breakpointchanged');
        event.breakpoint = breakpoint
        window.dispatchEvent(event);
      }

      window.breakpoints.current = breakpoint
    }
  },
}

window.addEventListener("resize", function(){
  window.breakpoints.change()
}, false);

//initial
window.breakpoints.change()
