import SmoothScroll from "smooth-scroll";

var scroll = new SmoothScroll();

window.contact = {
  contact_selector: '.m-contact',
  list_selector: '.m-contact__list',
  is_open_class: 'is-opened',

  init: function() {
    const loadmore = document.querySelector('body.single-association .m-contact__loadmorebutton');

    if (loadmore) {
      loadmore.addEventListener('click', function (e) {
        const contact = loadmore.closest(window.contact.contact_selector)
        const contactlist = contact.querySelector(window.contact.list_selector)

        if (contact.classList.contains(window.contact.is_open_class)) {
          contactlist.removeAttribute('style')
          contact.classList.remove(window.contact.is_open_class)

          // scroll
          if ((window.breakpoints.current === 'xs') || (window.breakpoints.current === 'sm') || (window.breakpoints.current === 'md')) {
            let options = {speed: 300, speedAsDuration: true, easing: 'easeInOutCubic', offset: function(anchor, toggle) { return 200; }};
            scroll.animateScroll(contactlist, null, options);
          }
        } else {
          contactlist.style.maxHeight = contactlist.scrollHeight + 'px'
          contact.classList.add(window.contact.is_open_class)
        }

        return false;
      });
    }
  },

  resize: function() {
    if (document.body.classList.contains('single-association')) {
      const contact = document.querySelector(window.contact.contact_selector)
      const contactlist = document.querySelector(window.contact.list_selector)

      if (contact.classList.contains(window.contact.is_open_class)) {
        contactlist.style.maxHeight = contactlist.scrollHeight + 'px'
      }
    }
  }
}

window.addEventListener('load', function() {
	window.contact.init()
});

const windowWidth = window.innerWidth;

window.addEventListener('resize', function () {
  if (window.innerWidth !== windowWidth) {
    window.contact.resize();
  }
});
