function preload(images, callback) {
  let done = () => {
    counter--;
    if (counter === 0) {
      callback(errors.length ? errors : null);
    }
  };
  let error = function() {
    errors.push(this.src);  // eslint-disable-line no-invalid-this
    done();
  };

  let counter = images.length;
  let errors = [];
  images.forEach((image) => {
    let img = new Image();
    img.onload = done;
    img.onerror = error;
    img.src = image.getAttribute('src');
  });
}

window.preload = {
  preload: function(images, callback) {
    var preloaded = new preload(images, callback)
  }
}
