import {tns} from 'tiny-slider/src/tiny-slider'

window.topslider = {
  load: function(elements) {
    const images = elements.querySelectorAll('img')

    if (images.length > 0) {
      window.preload.preload(images, function(){
        window.topslider.slider(elements)
      })
    }
    else {
      window.topslider.slider(elements)
    }
  },

  init: function() {
    const elements = document.querySelectorAll('.m-topslider__list')
    if (elements) {
      for (const element of elements) {
        window.topslider.load(element)
      }
    }
  },

  slider: function(element) {
    const navcontainer = document.querySelector('.m-topslider__nav');

    tns({
      "container": element,
      "speed": 600,
      "autoplay": true,
      "slideBy": 1,
      "items": 1,
      "gutter": 0,
      "mouseDrag": true,
      "lazyload": false,
      "swipeAngle": false,
      "nav": true,
      "navContainer": navcontainer,
      "controls": false,
      // "preventScrollOnTouch": true
    })
  }
}

window.topslider.init()
