import {domReady} from '@roots/sage/client';

// Import Bootstrap
import 'bootstrap';

/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err);
  }

  // application code
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);

import.meta.webpackHot?.accept(main);

require.context('./', true, /\.js$/);

var cache = {};
window.dgpmconfig = pgconfig;

//load all js modules
function importAll(r) {
  r.keys().forEach((key) => (cache[key] = r(key)));
}

importAll(require.context('./', true, /\.js$/));
