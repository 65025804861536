// https://masonry.desandro.com/
import Masonry from 'masonry-layout';

window.press = {
  init: function() {
      window.press.load()
  },

  load: function() {
    // masonry
    var grid = document.querySelector('.m-press-list__list');

    if (grid) {
      var msnry = new Masonry( grid, {
        columnWidth: '.m-press-list__listitem',
        itemSelector: '.m-press-list__listitem',
        percentPosition: true
      });
    }
  }
}

window.addEventListener('load', function() {
	window.press.init()
});

window.addEventListener('resize', function() {
	window.press.init()
});
