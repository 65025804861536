window.scrollToTop = {
  init: function () {
    function doOnDocumentLoaded() {
      const toTopBtn = document.getElementById('toTopBtn');

      if (toTopBtn) {
        toTopBtn.addEventListener('click', function (e) {
          window.scrollToTop.topFunction();
        });

        if (window.pageYOffset > window.innerHeight) {
          toTopBtn.closest('.l-footer__top').classList.add('is-fixed');
        } else {
          toTopBtn.closest('.l-footer__top').classList.remove('is-fixed');
        }

        const footer = document.querySelector('.l-footer');
        window.scrollToTop.elementInViewport(footer, toTopBtn);
      }
    }

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', doOnDocumentLoaded);
    } else {
      doOnDocumentLoaded();
    }
  },

  // When the user clicks on the button, scroll to the top of the document
  topFunction: function () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },

  elementInViewport: function (footer, toTopBtn) {
    const observer = new window.IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (!toTopBtn.closest('.l-footer__top').classList.contains('is-footer')) {
          toTopBtn.closest('.l-footer__top').classList.add('is-footer');
        }
        return;
      }

      if (toTopBtn.closest('.l-footer__top').classList.contains('is-footer')) {
        toTopBtn.closest('.l-footer__top').classList.remove('is-footer');
      }
    }, {
      root: null,
      threshold: 0.05, // set offset 0.1 means trigger if atleast 10% of element in viewport
    })

    observer.observe(footer);
  },
};

window.addEventListener('load', function() {
	window.scrollToTop.init();
});

window.addEventListener('resize', function () {
	window.scrollToTop.init();
});

window.addEventListener('scroll', function(event) {
	window.scrollToTop.init();
}, false)
