import {tns} from 'tiny-slider/src/tiny-slider'

window.newsteaser = {
  load: function(elements) {
    const images = elements.querySelectorAll('img')

    if (images.length > 0) {
      window.preload.preload(images, function(){
        window.newsteaser.slider(elements)
      })
    }
    else {
      window.newsteaser.slider(elements)
    }
  },

  init: function() {
    const elements = document.querySelectorAll('.m-newsteaser__list')
    if (elements) {
      for (const element of elements) {
        window.newsteaser.load(element)
      }
    }
  },

  slider: function(element) {
    tns({
      "container": element,
      "speed": 600,
      "autoplay": false,
      "slideBy": 1,
      "mouseDrag": true,
      "lazyload": false,
      "swipeAngle": false,
      "nav": false,
      "controls": true,
      "preventScrollOnTouch": true,
      "responsive": {
        320: {
          "gutter": 12,
          "items": 1.11,
        },
        768: {
          "gutter": 16,
          "items": 2     
        },
        992: {
          "gutter": 32,
          "items": 2
        },
        1200: {
          "gutter": 32,
          "items": 2
        }
      }
    })
  }
}

window.newsteaser.init()
